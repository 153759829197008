import PropTypes from 'prop-types'
import React from 'react'
import CalculateDate from '../CalculateDate'
import * as convert from '../ToFarenheit'
import IconDict from '../weatherIcon'

const Card = (props) => {
  const { day, offset, useFarenheit } = props
  // const img = `https://openweathermap.org/img/wn/${day.weather[0].icon}@2x.png`
  return (
    <div className="bg-blue-lighter lg:text-sm lg:p-1 p-3 lg:py-4 rounded-lg text-center">
      <div className="flex-col text-xl lg:text-base">
        <p>
          {CalculateDate(day.dt, offset)}
        </p>
        <div className="flex justify-between items-center lg:hidden">
          <span>{useFarenheit ? `${convert.kelvinToFahrenheit(day.temp.min)}˚F` : `${convert.kelvinToCelcius(day.temp.min)}˚C`}</span>
          <img src={IconDict[day.weather[0].icon]} alt="" className="m-1 object-contain" style={{ width: '40px', height: '40px' }} />
          <span>{useFarenheit ? `${convert.kelvinToFahrenheit(day.temp.max)}˚F` : `${convert.kelvinToCelcius(day.temp.max)}˚C`}</span>
        </div>
        <div className="justify-center items-center hidden lg:flex">
          <img src={IconDict[day.weather[0].icon]} alt="" className="m-2 object-contain" style={{ width: '40px', height: '40px' }} />
        </div>
      </div>
      <div className="hidden lg:flex justify-between">
        <span>{useFarenheit ? `${convert.kelvinToFahrenheit(day.temp.min)}˚F` : `${convert.kelvinToCelcius(day.temp.min)}˚C`}</span>
        <span>{useFarenheit ? `${convert.kelvinToFahrenheit(day.temp.max)}˚F` : `${convert.kelvinToCelcius(day.temp.max)}˚C`}</span>
      </div>
    </div>
  )
}

Card.propTypes = {
  day: PropTypes.shape({
    temp: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
    }).isRequired,
    dt: PropTypes.number.isRequired,
    weather: PropTypes.arrayOf(PropTypes.shape({
      icon: PropTypes.string,
    })).isRequired,
  }).isRequired,
  offset: PropTypes.string.isRequired,
  useFarenheit: PropTypes.bool.isRequired,
}

export default Card
