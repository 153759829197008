import PropTypes from 'prop-types'
import React from 'react'

const InfoCard = ({ body, title, icon }) => (
  <div className="bg-blue-lighter rounded-lg px-auto py-4">
    <div className="flex-col text-center">
      <p className="text-3xl text-center text-white font-semibold">{title}</p>
      <div className="flex justify-center items-center p-3">
        <span className="mt-3 iconify text-3xl" data-icon={icon} data-inline="false" />
        <span className="text-4xl pl-6 font-normal">
          {body}
        </span>
      </div>
    </div>
  </div>
)

InfoCard.propTypes = {
  body: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}

export default InfoCard
