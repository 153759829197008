import React from 'react'
import PropTypes from 'prop-types'
import CalculateTime from './CalculateTime'
import * as convert from './ToFarenheit'
import IconDict from './weatherIcon'

const HourlyCard = (props) => {
  const { hour, offset, useFarenheit } = props
  // const img = `https://openweathermap.org/img/wn/${hour.weather[0].icon}@2x.png`
  return (
    <div className="bg-blue-lighter p-2 md:p-3 lg:p-3 rounded-lg">
      <div className="flex-col text-sm md:text-lg lg:text-lg sm:text-sm">
        <div className="flex justify-center items-center">
          <p>
            {CalculateTime(hour.dt, offset, true)}
            {' '}
          </p>
        </div>
        <div className="flex justify-center items-center">
          <img src={IconDict[hour.weather[0].icon]} alt="" className="m-1 object-contain" style={{ width: '40px', height: '40px' }} />
        </div>
      </div>

      <div className="flex justify-center text-sm md:text-lg lg:text-lg sm:text-sm">
        <span>{useFarenheit ? `${convert.kelvinToFahrenheit(hour.temp)}˚F` : `${convert.kelvinToCelcius(hour.temp)}˚C`}</span>
      </div>
    </div>
  )
}

HourlyCard.propTypes = {
  hour: PropTypes.shape({
    temp: PropTypes.number.isRequired,
    dt: PropTypes.number.isRequired,
    weather: PropTypes.arrayOf(PropTypes.shape({
      icon: PropTypes.string,
    })).isRequired,
  }).isRequired,
  offset: PropTypes.string.isRequired,
  useFarenheit: PropTypes.bool.isRequired,
}

export default HourlyCard
