import PropTypes from 'prop-types'
import React from 'react'

const CardStatus = (props) => {
  const { windSpeed, useFarenheit } = props
  const speed = useFarenheit ? Math.round(windSpeed * 2.23693629) : windSpeed
  const unit = useFarenheit ? 'mph' : 'm/s'

  return (
    <div className="bg-blue-lighter rounded-lg px-auto py-2">
      <div className="flex-col items-center">
        <p className="text-3xl text-center text-white font-semibold">Wind Status</p>
        {/* Expand margin top to make the card taller */}
        <div className="flex justify-center items-center mt-3">
          <p className="mt-3 text-center text-white font-semibold">
            <i className="material-icons">air</i>
          </p>
          <span className="text-5xl pl-6 font-normal">
            {speed}
            <span className="text-4xl font-light ml-1">{unit}</span>
          </span>
        </div>

      </div>
    </div>
  )
}

CardStatus.propTypes = {
  windSpeed: PropTypes.number.isRequired,
  useFarenheit: PropTypes.bool.isRequired,
}

export default CardStatus
