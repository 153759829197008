const API_BASE = 'https://api.joineconomy.com/v1'

// Contact the economy backend to see if there is a user signed in
async function loginUser() {
  return fetch(
    `${API_BASE}/login`,
    {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    },
  )
    .then((response) => response)
    .catch(() => null)
}

export default loginUser
