import PropTypes from 'prop-types'
import React from 'react'

const AirQuality = (props) => {
  const {
    aqiData,
  } = props
  return (
    <>
      {aqiData > 0 && aqiData <= 50 && (
      <>
        <div className="rounded-full h-24 w-24 text-white text-2xl ring-4 ring-green-500 flex items-center justify-center">
          {aqiData}
        </div>
        <p className="text-white text-2xl mt-4">Good</p>
      </>
      )}

      {aqiData > 50 && aqiData <= 100 && (
      <>
        <div className="rounded-full h-24 w-24 text-white text-2xl ring-4 ring-yellow-500 flex items-center justify-center">
          {aqiData}
        </div>
        <p className="text-white text-2xl mt-4">Moderate</p>
      </>
      )}

      {aqiData > 100 && aqiData <= 150 && (
      <>
        <div className="rounded-full h-24 w-24 text-white text-2xl ring-4 ring-yellow-700 flex items-center justify-center">
          {aqiData}
        </div>
        <p className="text-white text-2xl mt-4">Unhealthy for Sensitive Groups</p>
      </>
      )}

      {aqiData > 150 && aqiData <= 200 && (
      <>
        <div className="rounded-full h-24 w-24 text-white text-2xl ring-4 ring-red-700 flex items-center justify-center">
          {aqiData}
        </div>
        <p className="text-white text-2xl mt-4">Unhealthy</p>
      </>
      )}

      {aqiData > 200 && aqiData <= 300 && (
      <>
        <div className="rounded-full h-24 w-24 text-white text-2xl ring-4 ring-purple-700 flex items-center justify-center">
          {aqiData}
        </div>
        <p className="text-white text-2xl mt-4">Very Unhealthy</p>
      </>
      )}

      {aqiData > 300 && (
      <>
        <div className="rounded-full h-24 w-24 text-white text-2xl ring-4 ring-red-900 flex items-center justify-center">
          {aqiData}
        </div>
        <p className="text-white text-2xl mt-4">Hazardous</p>
      </>
      )}
    </>
  )
}

AirQuality.propTypes = {
  aqiData: PropTypes.number.isRequired,
}

export default AirQuality
