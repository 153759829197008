import PropTypes from 'prop-types'
import React from 'react'

const Loading = ({ text, hidden }) => (
  <>
    { hidden && (
      <div loading="true" className="hidden">
        <div className="animate-spin">
          <span className="iconify text-lg" data-icon="ant-design:loading-3-quarters-outlined" data-inline="false" />
        </div>
        <h2 className="text-center text-white text-xl font-semibold">{text}</h2>
      </div>
    )}
    { !hidden && (
      <div loading="true" className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
        <div className="animate-spin">
          <span className="iconify text-lg" data-icon="ant-design:loading-3-quarters-outlined" data-inline="false" />
        </div>
        <h2 className="text-center text-white text-xl font-semibold">{text}</h2>
      </div>
    )}
  </>
)

Loading.propTypes = {
  text: PropTypes.PropTypes.string.isRequired,
  hidden: PropTypes.PropTypes.boolean.isRequired,
}

export default Loading
