import PropTypes from 'prop-types'
import React from 'react'
import Temperature from '../Temperature'
import AirQuality from '../AirQuality'
import IconDict from '../weatherIcon'

const LeftAside = (props) => {
  const {
    cityName, data, useFarenheit, aqiData,
  } = props
  // const img = `https://openweathermap.org/img/wn/${data.weather[0].icon}@2x.png`

  return (
    <div>
      <div className="flex-col pb-4">
        <div className="flex flex-col justify-start items-center h-full">
          {/* Location */}
          <div>
            <p className="text-gray-400 text-center font-bold text-2xl mt-7">
              <i className="material-icons">location_on</i>
              {' '}
              {cityName}
            </p>
          </div>
          {/* Weather Image at top */}
          <img className="m-4 object-contain" src={IconDict[data.weather[0].icon]} alt="weather icon for today" style={{ width: '75px', height: '75px' }} />
          <Temperature
            useFarenheit={useFarenheit}
            temp={data.temp}
          />
          <p className="text-gray-50 font-bold mt-10 mb-4 text-3xl">
            {data.weather[0].description}
          </p>
          <p className={`text-gray-50 font-bold text-2xl mt-4 py-4 ${aqiData < 0 ? 'hidden' : ''}`}>
            Air Quality Index&nbsp;
          </p>
          <AirQuality
            aqiData={aqiData}
          />
        </div>
      </div>
    </div>
  )
}

LeftAside.propTypes = {
  cityName: PropTypes.string.isRequired,
  data: PropTypes.shape({
    clouds: PropTypes.number.isRequired,
    temp: PropTypes.number.isRequired,
    weather: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  useFarenheit: PropTypes.bool.isRequired,
  aqiData: PropTypes.number.isRequired,
}

export default LeftAside
