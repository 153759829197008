import PropTypes from 'prop-types'
import React from 'react'

const HumidityCard = ({ humidity }) => (
  <div className="bg-blue-lighter rounded-lg px-auto py-2">
    <div className="flex-col">
      <p className="text-3xl text-center text-white font-semibold align-text-middle">Humidity</p>
      <div className="flex justify-center items-center mt-5">
        <p className="mt-3 text-center text-white font-semibold">
          <i className="material-icons">water_drop</i>
        </p>
        <span className="text-5xl pl-6 font-normal">
          {humidity}
          <span className="text-4xl font-lighter font-light ml-1">%</span>
        </span>
      </div>
      {/* Progress Bar */}
    </div>
  </div>
)

HumidityCard.propTypes = {
  humidity: PropTypes.number.isRequired,
}

export default HumidityCard
