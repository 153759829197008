import PropTypes from 'prop-types'
import React from 'react'
import DailyForecast from '../DailyForecast'
import HourlyForecast from '../HourlyForecast'
import CardStatus from '../CardStatus/CardStatus'
import HumidityCard from '../Humidity/HumidityCard'
import InfoCard from '../InfoCard/InfoCard'
import CalculateTime from '../CalculateTime'
import './RightAside.css'

const RightAside = (props) => {
  const {
    dailyForecast, data, offset, useFarenheit, hourlyForecast,
  } = props

  return (
    <>
      <div className="flex-col justify-center items-center text-white">

        {/* Create hourly cards for 18 hours */}
        <div className="hourly no-scrollbar gap-2 p-2">
          <HourlyForecast
            forecast={hourlyForecast}
            offset={offset}
            useFarenheit={useFarenheit}
          />
        </div>
        {/* Create daily cards for 5 days */}
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-7 mt-6 gap-2 p-2">
          <DailyForecast
            forecast={dailyForecast}
            offset={offset}
            useFarenheit={useFarenheit}
          />
        </div>

        <div className="flex justify-center items-center mb-2 mt-6 ml-4">
          <p className="text-4xl text-center font-bold">Today&apos;s Highlights</p>
        </div>

        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-2 m-2 text-2xl font-bold">
          <CardStatus windSpeed={data.wind_speed} useFarenheit={useFarenheit} />
          <HumidityCard humidity={data.humidity} />
          <InfoCard title="Sunrise" body={CalculateTime(data.sunrise, offset)} icon="bi:sunrise-fill" />
          <InfoCard title="Sunset" body={CalculateTime(data.sunset, offset)} icon="bi:sunset-fill" />
        </div>

      </div>
    </>
  )
}

RightAside.propTypes = {
  data: PropTypes.shape({
    humidity: PropTypes.number.isRequired,
    sunrise: PropTypes.number.isRequired,
    sunset: PropTypes.number.isRequired,
    wind_speed: PropTypes.number.isRequired,
  }).isRequired,
  dailyForecast: PropTypes.arrayOf(
    PropTypes.shape({
      dt: PropTypes.number.isRequired,
      sunrise: PropTypes.number.isRequired,
      sunset: PropTypes.number.isRequired,
      pressure: PropTypes.number.isRequired,
      humidity: PropTypes.number.isRequired,
      dew_point: PropTypes.number.isRequired,
      wind_speed: PropTypes.number.isRequired,
      wind_deg: PropTypes.number.isRequired,
      clouds: PropTypes.number.isRequired,
      pop: PropTypes.number.isRequired,
      uvi: PropTypes.number.isRequired,
      temp: PropTypes.shape({
        day: PropTypes.number.isRequired,
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        night: PropTypes.number.isRequired,
        eve: PropTypes.number.isRequired,
        morn: PropTypes.number.isRequired,
      }).isRequired,
      feels_like: PropTypes.shape({
        day: PropTypes.number.isRequired,
        night: PropTypes.number.isRequired,
        eve: PropTypes.number.isRequired,
        morn: PropTypes.number.isRequired,
      }).isRequired,
      weather: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          main: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          icon: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  ).isRequired,
  hourlyForecast: PropTypes.arrayOf(
    PropTypes.shape({
      dt: PropTypes.number.isRequired,
      pressure: PropTypes.number.isRequired,
      humidity: PropTypes.number.isRequired,
      dew_point: PropTypes.number.isRequired,
      wind_speed: PropTypes.number.isRequired,
      wind_deg: PropTypes.number.isRequired,
      clouds: PropTypes.number.isRequired,
      pop: PropTypes.number.isRequired,
      uvi: PropTypes.number.isRequired,
      temp: PropTypes.number.isRequired,
      feels_like: PropTypes.number.isRequired,
      weather: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          main: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          icon: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  ).isRequired,
  offset: PropTypes.string.isRequired,
  useFarenheit: PropTypes.bool.isRequired,
}

export default RightAside
