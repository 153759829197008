function CalculateDate(dt, offset) {
  const unixTimestamp = dt
  const milliseconds = unixTimestamp * 1000
  const dtFormat = new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    timeZone: `${offset}`,
  })

  return dtFormat.format(new Date(milliseconds))
}

export default CalculateDate
