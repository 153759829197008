import PropTypes from 'prop-types'
import React from 'react'
import Card from './Card/Card'

const DailyForecast = ({ forecast, offset, useFarenheit }) => (
  <>
    <Card day={forecast[0]} offset={offset} useFarenheit={useFarenheit} />
    <Card day={forecast[1]} offset={offset} useFarenheit={useFarenheit} />
    <Card day={forecast[2]} offset={offset} useFarenheit={useFarenheit} />
    <Card day={forecast[3]} offset={offset} useFarenheit={useFarenheit} />
    <Card day={forecast[4]} offset={offset} useFarenheit={useFarenheit} />
    <Card day={forecast[5]} offset={offset} useFarenheit={useFarenheit} />
    <Card day={forecast[6]} offset={offset} useFarenheit={useFarenheit} />
  </>
)

DailyForecast.propTypes = {
  forecast: PropTypes.arrayOf(
    PropTypes.shape({
      dt: PropTypes.number.isRequired,
      sunrise: PropTypes.number.isRequired,
      sunset: PropTypes.number.isRequired,
      pressure: PropTypes.number.isRequired,
      humidity: PropTypes.number.isRequired,
      dew_point: PropTypes.number.isRequired,
      wind_speed: PropTypes.number.isRequired,
      wind_deg: PropTypes.number.isRequired,
      clouds: PropTypes.number.isRequired,
      pop: PropTypes.number.isRequired,
      uvi: PropTypes.number.isRequired,
      temp: PropTypes.shape({
        day: PropTypes.number.isRequired,
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        night: PropTypes.number.isRequired,
        eve: PropTypes.number.isRequired,
        morn: PropTypes.number.isRequired,
      }).isRequired,
      feels_like: PropTypes.shape({
        day: PropTypes.number.isRequired,
        night: PropTypes.number.isRequired,
        eve: PropTypes.number.isRequired,
        morn: PropTypes.number.isRequired,
      }).isRequired,
      weather: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          main: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          icon: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  ).isRequired,
  offset: PropTypes.string.isRequired,
  useFarenheit: PropTypes.bool.isRequired,
}

export default DailyForecast
