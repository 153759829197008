import React, { useEffect, useState } from 'react'
// import PullToRefresh from 'pull-to-refresh-react'
import PullToRefresh from 'react-simple-pull-to-refresh'
import LeftAside from './LeftAside/LeftAside'
import RightAside from './RightAside/RightAside'
import Loading from './Loading'
// import menu from '../img/list.png'
/* global mapboxgl */
/* global MapboxGeocoder */

const API_KEY = '6a58b8ab67ce8dca110ee5f648d21334'
const OPEN_WEATHERMAP_BASE_URL = 'https://api.openweathermap.org'
const WEATHER_URL = `${OPEN_WEATHERMAP_BASE_URL}/data/2.5/onecall?appid=${API_KEY}&exclude=minutely`
const REVERSE_GEOCODE_URL = `${OPEN_WEATHERMAP_BASE_URL}/geo/1.0/reverse?appid=${API_KEY}`
const AIR_QUALITY_API_KEY = '1fd2f3b8-0462-44a8-8bed-42c76d67f0c1'
const AIR_QUALITY_URL = 'https://api.airvisual.com/v2/nearest_city'
const MAPBOX_API_KEY = 'pk.eyJ1IjoibW95b29rZXJlbWkiLCJhIjoiY2tuZmhpczdxMWtyMDJvbGxkd211OGF1MiJ9.5gvJHfg9WHNAZW2DLifFjA'

let geocoder
const SF_LAT = 37.7749
const SF_LON = -122.4194

function WeatherInput() {
  const [lat, setLat] = useState(localStorage.getItem('lastLat'))
  const [lon, setLon] = useState(localStorage.getItem('lastLon'))
  const [isLoading, setIsLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [useFarenheit, setUseFarenheit] = useState(localStorage.getItem('useFarenheit') === null || localStorage.getItem('useFarenheit') === 'true')
  const [data, setData] = useState(null)
  // TODO - once we create an air quality index component, remove the eslint-disable
  const [aqiData, setAqiData] = useState(null) // eslint-disable-line
  const [cityName, setCityName] = useState(localStorage.getItem('lastCity'))

  const toggleUnits = () => {
    localStorage.setItem('useFarenheit', !useFarenheit)
    setUseFarenheit(!useFarenheit)
  }

  const reverseGeocode = async () => {
    if (lat != null && lon != null) {
      const result = await fetch(
        `${REVERSE_GEOCODE_URL}&lat=${lat}&lon=${lon}&limit=1`,
      )
      const resultJson = await result.json()
      if (resultJson && resultJson.length === 1) {
        const name = `${resultJson[0].name}, ${resultJson[0].state}`
        localStorage.setItem('lastCity', name)
        setCityName(name)
      } else {
        alert('Cannnot find the weather for the location provided.')
      }
    }
  }

  const ParseResult = async (jsonResult) => {
    localStorage.setItem('lastCity', jsonResult.place_name)
    localStorage.setItem('lastLat', jsonResult.geometry.coordinates[1])
    localStorage.setItem('lastLon', jsonResult.geometry.coordinates[0])
    setIsLoading(true)
    setCityName(jsonResult.place_name)
    setLat(jsonResult.geometry.coordinates[1])
    setLon(jsonResult.geometry.coordinates[0])
  }

  const ResetSearchBox = () => {
    if (document.getElementsByClassName('mapboxgl-ctrl-geocoder').length !== 0) {
      const input = document.getElementsByClassName('mapboxgl-ctrl-geocoder--input')[0]
      input.value = ''
      const ogInput = document.querySelector('.mapboxgl-ctrl-geocoder--input')
      ogInput.parentNode.replaceChild(input, ogInput)
    }
  }

  const MapBoxInit = async () => {
    if (document.getElementsByClassName('mapboxgl-ctrl-geocoder').length === 0) {
      mapboxgl.accessToken = MAPBOX_API_KEY
      geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        types: 'region,place,locality,postcode',
      })
      geocoder.addTo('#geocoder')
      geocoder.on('result', (e) => {
        ParseResult(e.result)
      })
      const input = document.getElementsByClassName('mapboxgl-ctrl-geocoder--input')[0]
      input.placeholder = 'Enter a City or Zipcode'
      const ogInput = document.querySelector('.mapboxgl-ctrl-geocoder--input')
      ogInput.parentNode.replaceChild(input, ogInput)
    }
  }
  const CheckLocation = async (buttonPressed = false) => {
    if (window.navigator.geolocation) {
      // Geolocation available
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          setIsLoading(true)
          setCityName('')
          setLat(position.coords.latitude)
          setLon(position.coords.longitude)
        },
        (e) => {
          // Location permission denied
          if (e.code === 3) {
            alert('Cannot determine location as a timeout occured.')
            setLat(lat === null ? SF_LAT : lat)
            setLon(lon === null ? SF_LON : lon)
          } else if (lat == null || lon == null) {
            setLat(lat === null ? SF_LAT : lat)
            setLon(lon === null ? SF_LON : lon)
          } else if (buttonPressed) {
            alert('Cannot use location feature as permission denied. Go into your browser location settings and reset it to ask for permission. If you are on mobile, remove and re-add the app to your homescreen.')
          }
        },
        { timeout: 4000 },
      )
    } else {
      // Browser doesn't support geolocation feature
      setLat(lat === null ? SF_LAT : lat)
      setLon(lon === null ? SF_LON : lon)
    }
  }

  useEffect(() => {
    if (lat == null && lon == null) {
      CheckLocation()
    }
  }, [])

  useEffect(
    async () => {
      if (lat != null && lon != null) {
        setIsLoading(true)
        // If the user inputted a lat/lon or just loaded their current location
        // we need to reverse geocode to get the city name
        if (cityName === null || cityName === '') {
          await reverseGeocode()
        }
        localStorage.setItem('lastLat', lat)
        localStorage.setItem('lastLon', lon)
        const result = await fetch(`${WEATHER_URL}&lat=${lat}&lon=${lon}`)
        const resultJson = await result.json()

        const aqiResult = await fetch(`${AIR_QUALITY_URL}?lat=${lat}&lon=${lon}&key=${AIR_QUALITY_API_KEY}`)
        const aqiJson = await aqiResult.json()

        setAqiData(aqiJson.data.current != null ? aqiJson.data.current.pollution.aqius : -1)
        setData(resultJson)
        // console.log(aqiJson.data.current.pollution.aqius)
        // aqiData == aqiJson.data.current.pollution.aqius
        ResetSearchBox()
      }
      setIsLoading(false)
    },
    // We never change lat without also changing lon, no need to fire the effect twice
    [lon, refresh],
  )
  useEffect(
    async () => {
      if (document.getElementById('geocoder') !== null) {
        MapBoxInit()
      }
    },
    [isLoading],
  )
  const refreshFunc = () => { // eslint-disable-line
    return new Promise(() => { // eslint-disable-line
      setRefresh(!refresh).then()
    })
  }

  return (
    <>
      {/* Information that is presented to the user */}
      <PullToRefresh
        onRefresh={refreshFunc}
      >
        <div className="flex-col">

          {data == null && (
          <>
            <div loading="true" className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
              <Loading text="loading" hidden={false} />
            </div>
          </>
          )}
          {data != null && (
          <>
            <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-6 h-screen">
              <Loading text="loading" hidden={!isLoading} />
              <aside className="col-span-1 lg:col-span-2 bg-blue-lighter bg-opacity-70 h-full bg-cover bg-night-image md:bg-bottom bg-no-repeat bg-bottom">
                {/* <div className="flex justify-center mt-4">
                  <button type="button" height="20" width="20" onClick={ChangeScreen}>
                    <img src={menu} alt="menu" height="20" width="20" />
                  </button>
                </div> */}
                <div className="flex items-center justify-center">
                  <button
                    type="button"
                    className="text-white text-xl font-bold px-1 text-center mt-4 rounded"
                    onClick={() => CheckLocation(true)}
                  >
                    <i className="material-icons">near_me</i>
                  </button>
                  <div className="flex justify-center pb-4 pt-8 px-2" id="geocoder" />
                  <button
                    type="button"
                    className="text-white  text-xl font-bold pr-2 mt-4 rounded"
                    onClick={toggleUnits}
                  >
                    {useFarenheit ? '˚C' : '˚F'}
                  </button>
                </div>

                {/* Detail view going in-depth about the weather right now */}
                <LeftAside
                  cityName={cityName.endsWith(', United States') ? cityName.substring(0, cityName.length - 15) : cityName}
                  data={data.current}
                  useFarenheit={useFarenheit}
                  aqiData={aqiData}
                />
              </aside>

              <aside className="col-span-1 lg:col-span-4 bg-blue-dark h-full">
                <RightAside
                  cityName={cityName}
                  offset={data.timezone}
                  data={data.current}
                  useFarenheit={useFarenheit}
                  dailyForecast={data.daily.slice(1, 8)}
                  hourlyForecast={data.hourly.slice(1, 20)}
                />
              </aside>
            </div>
          </>
          )}
        </div>
      </PullToRefresh>
    </>
  )
}

export default WeatherInput
