import PropTypes from 'prop-types'
import React from 'react'

import * as convert from './ToFarenheit'

const Temperature = ({ temp, useFarenheit }) => (
  <p>
    <span className="font-extrabold text-white text-7xl">
      { useFarenheit ? `${convert.kelvinToFahrenheit(temp)}` : `${convert.kelvinToCelcius(temp)}`}
    </span>
    <span className="text-gray-400 text-3xl">{useFarenheit ? '˚F' : '˚C'}</span>

    {/* {prefix || ''} */}
  </p>
)

Temperature.propTypes = {
  // prefix: PropTypes.string,
  temp: PropTypes.number.isRequired,
  useFarenheit: PropTypes.bool.isRequired,
}

export default Temperature
