import React, { useEffect, useState } from 'react'
import WeatherInput from './components/WeatherInput'
import Layout from './components/Layout'
import 'firebase/auth'
import 'firebase/firestore'
import loginUser from './api'
import Loading from './components/Loading'

function App() {
  const [user, setUser] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const getUser = async () => {
    const res = await loginUser()
    if (res?.status === 200) {
      const resJson = await res.json()
      return resJson.user
    }
    return null
  }

  const use = () => {
    window.location.href = 'https://signup.joineconomy.com?redirect=https://weather.joineconomy.com'
  }

  useEffect(async () => {
    const user2 = await getUser()
    setUser(user2)
    setLoading(false)
  }, [])

  return (
    <>
      { user !== null && !isLoading && (
        <Layout>
          <WeatherInput />
        </Layout>
      )}
      { user === null && !isLoading && (
        use()
      )}
      { isLoading && (
        <Loading text="logging in..." hidden={false} />
      )}
    </>
  )
}

export default App
