export function kelvinToFahrenheit(temp) {
  return (
    Math.floor(((temp - 273) * 1.8) + 32)
  )
}

export function kelvinToCelcius(temp) {
  return (
    Math.floor(temp - 273)
  )
}
