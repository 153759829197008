import PropTypes from 'prop-types'
import React from 'react'
import HourlyCard from './HourlyCard'

const HourlyForecast = ({ forecast, offset, useFarenheit }) => (
  <>
    <HourlyCard hour={forecast[0]} offset={offset} useFarenheit={useFarenheit} />
    <HourlyCard hour={forecast[1]} offset={offset} useFarenheit={useFarenheit} />
    <HourlyCard hour={forecast[2]} offset={offset} useFarenheit={useFarenheit} />
    <HourlyCard hour={forecast[3]} offset={offset} useFarenheit={useFarenheit} />
    <HourlyCard hour={forecast[4]} offset={offset} useFarenheit={useFarenheit} />
    <HourlyCard hour={forecast[5]} offset={offset} useFarenheit={useFarenheit} />
    <HourlyCard hour={forecast[6]} offset={offset} useFarenheit={useFarenheit} />
    <HourlyCard hour={forecast[7]} offset={offset} useFarenheit={useFarenheit} />
    <HourlyCard hour={forecast[8]} offset={offset} useFarenheit={useFarenheit} />
    <HourlyCard hour={forecast[9]} offset={offset} useFarenheit={useFarenheit} />
    <HourlyCard hour={forecast[10]} offset={offset} useFarenheit={useFarenheit} />
    <HourlyCard hour={forecast[11]} offset={offset} useFarenheit={useFarenheit} />
    <HourlyCard hour={forecast[12]} offset={offset} useFarenheit={useFarenheit} />
    <HourlyCard hour={forecast[13]} offset={offset} useFarenheit={useFarenheit} />
    <HourlyCard hour={forecast[14]} offset={offset} useFarenheit={useFarenheit} />
    <HourlyCard hour={forecast[15]} offset={offset} useFarenheit={useFarenheit} />
    <HourlyCard hour={forecast[16]} offset={offset} useFarenheit={useFarenheit} />
    <HourlyCard hour={forecast[17]} offset={offset} useFarenheit={useFarenheit} />
  </>
)

HourlyForecast.propTypes = {
  forecast: PropTypes.arrayOf(
    PropTypes.shape({
      dt: PropTypes.number.isRequired,
      pressure: PropTypes.number.isRequired,
      humidity: PropTypes.number.isRequired,
      dew_point: PropTypes.number.isRequired,
      wind_speed: PropTypes.number.isRequired,
      wind_deg: PropTypes.number.isRequired,
      clouds: PropTypes.number.isRequired,
      pop: PropTypes.number.isRequired,
      uvi: PropTypes.number.isRequired,
      temp: PropTypes.number.isRequired,
      feels_like: PropTypes.number.isRequired,
      weather: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          main: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          icon: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  ).isRequired,
  offset: PropTypes.string.isRequired,
  useFarenheit: PropTypes.bool.isRequired,
}

export default HourlyForecast
