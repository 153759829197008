import clearNight from '../img/night.png'
import clearDay from '../img/day.png'
import thunder from '../img/Thunderstorm.png'
import snow from '../img/Snow.png'
import fog from '../img/fog.png'
import rain from '../img/Rain.png'
import drizzle from '../img/drizzle.png'
import cloudy from '../img/MostlyCloudy.png'
import partCloudyDay from '../img/PartlyCloudy.png'
import partCloudyNight from '../img/PartlyCloudyNight.png'

const IconDict = {
  '01n': clearNight,
  '01d': clearDay,
  '02n': partCloudyNight,
  '02d': partCloudyDay,
  '03n': partCloudyNight,
  '03d': partCloudyDay,
  '04n': cloudy,
  '04d': cloudy,
  '09n': drizzle,
  '09d': drizzle,
  '10n': rain,
  '10d': rain,
  '11n': thunder,
  '11d': thunder,
  '13n': snow,
  '13d': snow,
  '50n': fog,
  '50d': fog,
}

export default IconDict
