function CalculateTime(dt, offset, justHour = false) {
  const unixTimestamp = dt
  const milliseconds = unixTimestamp * 1000
  let dtFormat = new Intl.DateTimeFormat('en-US',
    {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: `${offset}`,
    })
  if (justHour) {
    dtFormat = new Intl.DateTimeFormat('en-US',
      {
        hour: 'numeric',
        timeZone: `${offset}`,
      })
  }
  return dtFormat.format(new Date(milliseconds))
}

export default CalculateTime
